//@flow
import * as React from 'react'
import EventCardRowWithQuery from '../components/EventCardRowWithQuery'
import EventCardRowHorizontalWithQuery from '../components/EventCardRowHorizontalWithQuery'

type EventCardsProps = {
  query: Object,
  eventCardHorizontal?: boolean,
  showOccurrenceDateRange?: boolean,
}
const EventCardList = ({
  query,
  eventCardHorizontal = false,
  showOccurrenceDateRange = false,
}: EventCardsProps) => {
  return eventCardHorizontal ? (
    <EventCardRowHorizontalWithQuery
      query={query}
      showOccurrenceDateRange={showOccurrenceDateRange}
    />
  ) : (
    <EventCardRowWithQuery
      query={query}
      showOccurrenceDateRange={showOccurrenceDateRange}
    />
  )
}

export default EventCardList
