// @flow
import React, { type Node } from 'react'
import { css } from '@emotion/core'
import Text from './Text'
import theme from '../../utils/theme'

type Props = {
  children: Node,
  title: string,
  actions?: Node,
  className?: string,
}

function EventCardListContainer({
  children,
  title,
  actions,
  className,
}: Props) {
  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 430px;
        padding: 0 10px;
      `}
    >
      <div
        css={css`
          padding: 24px 0;
          width: 100%;
          max-width: 1280px;
          ${theme.mq.xl} {
            padding: 40px 0;
          }
          ${theme.mq.xxl} {
            padding: 80px 0;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            margin-bottom: 8px;
            ${theme.mq.md} {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              justify-content: space-between;
            }
          `}
        >
          <Text
            size={Text.Sizes.extraLarge}
            font={Text.Fonts.primary}
            weight={Text.Weights.bold}
            color={Text.Colors.primary}
            css={css`
              margin-bottom: 16px;
            `}
          >
            {title}
          </Text>

          {actions ? <div>{actions}</div> : null}
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}
export default EventCardListContainer
