// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Later = require("later");
var Moment = require("moment");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var MomentRe$PciDataSync = require("./lib/MomentRe.bs.js");

var Venue = /* module */[];

var $$Image = /* module */[];

function tToJs(param) {
  return {
          id: param[/* id */0],
          name: param[/* name */1],
          slug: param[/* slug */2],
          startDate: param[/* startDate */3],
          endDate: param[/* endDate */4],
          occurrenceIndex: param[/* occurrenceIndex */5],
          occurrenceCount: param[/* occurrenceCount */6],
          occurrences: param[/* occurrences */7],
          ageGroup: param[/* ageGroup */8],
          type_: param[/* type_ */9],
          keywords: param[/* keywords */10],
          venueId: param[/* venueId */11],
          venue: param[/* venue */12],
          mainImage: param[/* mainImage */13]
        };
}

function tFromJs(param) {
  return /* record */[
          /* id */param.id,
          /* name */param.name,
          /* slug */param.slug,
          /* startDate */param.startDate,
          /* endDate */param.endDate,
          /* occurrenceIndex */param.occurrenceIndex,
          /* occurrenceCount */param.occurrenceCount,
          /* occurrences */param.occurrences,
          /* ageGroup */param.ageGroup,
          /* type_ */param.type_,
          /* keywords */param.keywords,
          /* venueId */param.venueId,
          /* venue */param.venue,
          /* mainImage */param.mainImage
        ];
}

var $$Event = /* module */[
  /* tToJs */tToJs,
  /* tFromJs */tFromJs
];

function $$event(e) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "startDate",
                e[/* startDate */3].toJSON()
              ],
              /* :: */[
                /* tuple */[
                  "endDate",
                  e[/* endDate */4].toJSON()
                ],
                /* [] */0
              ]
            ]);
}

var Encode = /* module */[/* event */$$event];

function occurrenceType(json) {
  var typeString = Json_decode.string(json);
  switch (typeString) {
    case "Daily" : 
        return /* Daily */0;
    case "Monthly" : 
        return /* Monthly */2;
    case "Weekly" : 
        return /* Weekly */1;
    case "Yearly" : 
        return /* Yearly */3;
    default:
      return /* None */4;
  }
}

function occurrenceFieldsDecoder(json) {
  var eta = Json_decode.field("endDate", Json_decode.string, json);
  return /* record */[
          /* endDate */MomentRe$PciDataSync.moment(undefined, eta),
          /* type_ */Json_decode.field("type", occurrenceType, json)
        ];
}

function venueDecoder(json) {
  return /* record */[
          /* id */"",
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* shortDescription */Json_decode.optional((function (param) {
                  return Json_decode.field("shortDescription", Json_decode.string, param);
                }), json),
          /* description */Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json)
        ];
}

function imageDecoder(json) {
  var fileDecoder = function (json) {
    return /* record */[/* url */Json_decode.field("url", Json_decode.string, json)];
  };
  var json$1 = json;
  return /* record */[
          /* title */Json_decode.field("title", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json$1),
          /* file */Json_decode.field("file", fileDecoder, json$1)
        ];
}

function $$event$1(json) {
  var eta = Json_decode.field("startDate", Json_decode.string, json);
  var eta$1 = Json_decode.field("endDate", Json_decode.string, json);
  return /* record */[
          /* id */"",
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* slug */Json_decode.field("slug", Json_decode.string, json),
          /* startDate */MomentRe$PciDataSync.moment(undefined, eta),
          /* endDate */MomentRe$PciDataSync.moment(undefined, eta$1),
          /* occurrenceIndex */0,
          /* occurrenceCount */0,
          /* occurrences */Json_decode.optional((function (param) {
                  return Json_decode.field("occurrences", (function (param) {
                                return Json_decode.list(occurrenceFieldsDecoder, param);
                              }), param);
                }), json),
          /* ageGroup */Json_decode.field("ageGroup", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          /* type_ */Json_decode.field("type", (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          /* keywords */Json_decode.optional((function (param) {
                  return Json_decode.field("keywords", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          /* venueId */Json_decode.field("venueId", Json_decode.string, json),
          /* venue */Json_decode.field("venue", venueDecoder, json),
          /* mainImage */Json_decode.field("mainImage", (function (param) {
                  return Json_decode.optional(imageDecoder, param);
                }), json)
        ];
}

var Decode = /* module */[
  /* occurrenceType */occurrenceType,
  /* occurrenceFieldsDecoder */occurrenceFieldsDecoder,
  /* venueDecoder */venueDecoder,
  /* imageDecoder */imageDecoder,
  /* event */$$event$1
];

function getScheduleByDateAndType(date, type_) {
  var d = date.clone().utc();
  switch (type_) {
    case 0 : 
        return Later.parse.recur().on(d.hour()).hour().on(d.minute()).minute();
    case 1 : 
        return Later.parse.recur().on(d.hour()).hour().on(d.minute()).minute().on(d.day() + 1 | 0).dayOfWeek();
    case 2 : 
        return Later.parse.recur().on(d.date()).dayOfMonth();
    case 3 : 
        return Later.parse.recur().on(1).year();
    case 4 : 
        return Later.parse.recur().every(1).day();
    
  }
}

function getDatesBySchedule(schedule, startDate, endDate) {
  var sd = startDate.clone().utc();
  var ed = endDate.clone().utc();
  var scheduleDates = Later.schedule(schedule).next(Pervasives.max_int, sd.toDate(), ed.toDate());
  return $$Array.to_list($$Array.map((function (d) {
                    return Moment(d);
                  }), scheduleDates));
}

function buildNewEventByScheduleDates(occurrenceIndex, $staropt$star, $$event, startDateList, endDateList) {
  var count = $staropt$star !== undefined ? $staropt$star : 0;
  if (startDateList) {
    var occI = occurrenceIndex + 1 | 0;
    var occC = count + 1 | 0;
    return List.append(/* :: */[
                /* record */[
                  /* id */$$event[/* id */0] + ("-" + (String(occI) + ("-" + String(occC)))),
                  /* name */$$event[/* name */1],
                  /* slug */$$event[/* slug */2],
                  /* startDate */startDateList[0],
                  /* endDate */List.nth(endDateList, count),
                  /* occurrenceIndex */occI,
                  /* occurrenceCount */occC,
                  /* occurrences */$$event[/* occurrences */7],
                  /* ageGroup */$$event[/* ageGroup */8],
                  /* type_ */$$event[/* type_ */9],
                  /* keywords */$$event[/* keywords */10],
                  /* venueId */$$event[/* venueId */11],
                  /* venue */$$event[/* venue */12],
                  /* mainImage */$$event[/* mainImage */13]
                ],
                /* [] */0
              ], buildNewEventByScheduleDates(occurrenceIndex, occC, $$event, startDateList[1], endDateList));
  } else {
    return /* [] */0;
  }
}

function parseSingleOccurrence(occurrence, $$event, occurrenceIndex) {
  var startDateList = getDatesBySchedule(getScheduleByDateAndType($$event[/* startDate */3], occurrence[/* type_ */1]), $$event[/* startDate */3], occurrence[/* endDate */0]);
  var endDateList = getDatesBySchedule(getScheduleByDateAndType($$event[/* endDate */4], occurrence[/* type_ */1]), $$event[/* endDate */4], occurrence[/* endDate */0]);
  return buildNewEventByScheduleDates(occurrenceIndex, 0, $$event, startDateList, endDateList);
}

function parseOccurrencesList(oList, $$event) {
  var _$staropt$star = /* [] */0;
  var _$staropt$star$1 = 0;
  var _l = oList;
  while(true) {
    var l = _l;
    var $staropt$star = _$staropt$star$1;
    var $staropt$star$1 = _$staropt$star;
    var result = $staropt$star$1 !== undefined ? $staropt$star$1 : /* [] */0;
    var occurrenceIndex = $staropt$star !== undefined ? $staropt$star : 0;
    if (l) {
      var psoResult = parseSingleOccurrence(l[0], $$event, occurrenceIndex);
      _l = l[1];
      _$staropt$star$1 = occurrenceIndex + 1 | 0;
      _$staropt$star = List.append(psoResult, result);
      continue ;
    } else {
      return result;
    }
  };
}

var Malformed_Event = Caml_exceptions.create("PCI-PciDataSync.Malformed_Event");

function validateEvent(e) {
  if (e[/* endDate */4].diff(e[/* startDate */3], "hours") > 24.0) {
    throw [
          Malformed_Event,
          "Event cannot be longer than 24 hours",
          e[/* slug */2]
        ];
  }
  return e;
}

function generateOccurrencesByEvent(e) {
  var exit = 0;
  var _event;
  try {
    _event = validateEvent(e);
    exit = 1;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Malformed_Event) {
      console.log(exn[1], exn[2]);
      return /* [] */0;
    } else {
      throw exn;
    }
  }
  if (exit === 1) {
    var match = e[/* occurrences */7];
    if (match !== undefined) {
      return List.append(/* [] */0, parseOccurrencesList(match, e));
    } else {
      return /* :: */[
              /* record */[
                /* id */e[/* id */0],
                /* name */e[/* name */1],
                /* slug */e[/* slug */2],
                /* startDate */e[/* startDate */3],
                /* endDate */e[/* endDate */4],
                /* occurrenceIndex */1,
                /* occurrenceCount */1,
                /* occurrences */e[/* occurrences */7],
                /* ageGroup */e[/* ageGroup */8],
                /* type_ */e[/* type_ */9],
                /* keywords */e[/* keywords */10],
                /* venueId */e[/* venueId */11],
                /* venue */e[/* venue */12],
                /* mainImage */e[/* mainImage */13]
              ],
              /* [] */0
            ];
    }
  }
  
}

function generateOccurrencesByEventList(events) {
  if (events) {
    var newEvents = generateOccurrencesByEvent(events[0]);
    return List.append(newEvents, generateOccurrencesByEventList(events[1]));
  } else {
    return events;
  }
}

function printFriendlyEvent(e) {
  console.log("                Id:", e[/* id */0]);
  console.log("              Name:", e[/* name */1]);
  console.log("              Slug:", e[/* slug */2]);
  console.log("        Start Date:", e[/* startDate */3].toJSON());
  console.log("          End Date:", e[/* endDate */4].toJSON());
  console.log("     DR Start Date:", e[/* startDate */3].toJSON());
  console.log("       DR End Date:", e[/* endDate */4].toJSON());
  console.log("  Occurrence Index:", e[/* occurrenceIndex */5]);
  console.log("  Occurrence Count:", e[/* occurrenceCount */6]);
  var match = e[/* type_ */9];
  var type_ = $$Array.of_list(match !== undefined ? match : /* :: */[
          "No type",
          /* [] */0
        ]);
  var match$1 = e[/* mainImage */13];
  var mainImage;
  if (match$1 !== undefined) {
    var match$2 = match$1[/* title */0];
    mainImage = match$2 !== undefined ? match$2 : "untitled";
  } else {
    mainImage = "No main image";
  }
  console.log("Type:", type_.join(), "- Venue:", e[/* venue */12][/* name */1], "- Main image:", mainImage);
  console.log("----");
  return /* () */0;
}

function printFriendlyEventList(l) {
  if (l) {
    printFriendlyEvent(l[0]);
    printFriendlyEventList(l[1]);
    return /* () */0;
  } else {
    return /* () */0;
  }
}

function getEventDateRange(e) {
  var list = generateOccurrencesByEvent(e);
  var length = List.length(list);
  if (length > 1) {
    var lastEvent = List.nth(list, List.length(list) - 1 | 0);
    return /* tuple */[
            e[/* startDate */3],
            lastEvent[/* startDate */3]
          ];
  } else {
    return /* tuple */[
            e[/* startDate */3],
            e[/* endDate */4]
          ];
  }
}

function isEventRecurring(e) {
  return e[/* occurrences */7] !== undefined;
}

exports.Venue = Venue;
exports.$$Image = $$Image;
exports.$$Event = $$Event;
exports.Encode = Encode;
exports.Decode = Decode;
exports.getScheduleByDateAndType = getScheduleByDateAndType;
exports.getDatesBySchedule = getDatesBySchedule;
exports.buildNewEventByScheduleDates = buildNewEventByScheduleDates;
exports.parseSingleOccurrence = parseSingleOccurrence;
exports.parseOccurrencesList = parseOccurrencesList;
exports.Malformed_Event = Malformed_Event;
exports.validateEvent = validateEvent;
exports.generateOccurrencesByEvent = generateOccurrencesByEvent;
exports.generateOccurrencesByEventList = generateOccurrencesByEventList;
exports.printFriendlyEvent = printFriendlyEvent;
exports.printFriendlyEventList = printFriendlyEventList;
exports.getEventDateRange = getEventDateRange;
exports.isEventRecurring = isEventRecurring;
/* later Not a pure module */
