//@flow
import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import moment from 'moment'
import { Link } from 'gatsby'
import { gql } from 'apollo-boost'
import { AgeGroups } from '../../constants/ageGroups'
import theme from '../../utils/theme'
import { hoverShadow } from '../../utils/styles'

import General from '../layoutHelpers/General'

const cardShadow = css`
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.06);
`

const Container = styled(Link)`
  position: relative;
  width: calc(100%);
  height: 360px;
  text-decoration: none;
  background-color: ${theme.colors.invertedFont};
  cursor: pointer;
  backface-visibility: hidden;
  ${cardShadow}
  ${hoverShadow}
`

const Details = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ImageContainer = styled.img`
  width: 100%;
  height: 187px;
  margin: 0;
  overflow: hidden;
  object-fit: cover;
`

const ContainerHorizontal = styled(Link)`
  position: relative;
  background-color: ${theme.colors.invertedFont};
  width: 100%;
  height: 95px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border: 0;
  ${cardShadow}
  ${hoverShadow}
  ${theme.mq.md} {
    height: 102px;
  }
`

const DetailsHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ImageContainerHorizontal = styled.img`
  object-fit: cover;
  width: 90px;
  height: 95px;
  margin: 0;
  padding: 1px;
  ${theme.mq.md} {
    width: 150px;
    height: 102px;
  }
`

const Name = styled.h4`
  font-weight: bold;
  color: ${theme.colors.primaryFont};
`

const NameHorizontal = styled(Name)`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  width: 90%;
  overflow: hidden;
`

const VenueName = styled.div`
  color: ${theme.colors.primaryFont};
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Date = styled.div`
  color: ${theme.colors.primaryFont};
  font-size: 14px;
`

type Props = {
  event: PCIEvent,
  to: string,
  showOccurrenceDateRange?: boolean,
}

export const eventCardFieldsFragment = gql`
  fragment eventCardFields on Event {
    id
    slug
    name
    ageGroup
    imageUrl
    startDate
    occurrenceDateRangeStartDate
    occurrenceDateRangeEndDate
    recurring
    venue {
      id
      name
    }
  }
`

export class EventCard extends React.PureComponent<Props> {
  static fragments = {
    eventCardFields: eventCardFieldsFragment,
  }
  render() {
    const {
      event: {
        name,
        venue,
        startDate,
        imageUrl,
        ageGroup,
        occurrenceDateRangeStartDate,
        occurrenceDateRangeEndDate,
        recurring,
      },
      to = '',
      showOccurrenceDateRange,
    } = this.props

    return (
      <Container to={to}>
        {AgeGroups[ageGroup] === AgeGroups.TWENTY_ONE_PLUS && (
          <General.EventCardLabel>
            <General.EventCardLabelText>
              {AgeGroups[ageGroup]}
            </General.EventCardLabelText>
          </General.EventCardLabel>
        )}
        <ImageContainer src={`${imageUrl}?w=220`} />
        <Details>
          <Name>{name}</Name>
          <div>
            {showOccurrenceDateRange && recurring ? (
              <Date>
                {moment().isSameOrAfter(occurrenceDateRangeStartDate)
                  ? 'Now until'
                  : moment(occurrenceDateRangeStartDate).format('MMM D')}{' '}
                - {moment(occurrenceDateRangeEndDate).format('MMM D')}{' '}
              </Date>
            ) : (
              <Date>{moment(startDate).format('ddd, MMM D | h:mm')}</Date>
            )}
            {venue && <VenueName>{venue.name}</VenueName>}
          </div>
        </Details>
      </Container>
    )
  }
}

export class EventCardHorizontal extends React.PureComponent<Props> {
  static fragments = {
    eventCardFields: eventCardFieldsFragment,
  }
  render() {
    const {
      event: {
        name,
        venue,
        startDate,
        imageUrl,
        ageGroup,
        occurrenceDateRangeStartDate,
        occurrenceDateRangeEndDate,
        recurring,
      },
      to = '',
      showOccurrenceDateRange,
    } = this.props

    return (
      <ContainerHorizontal to={to}>
        {AgeGroups[ageGroup] === AgeGroups.TWENTY_ONE_PLUS && (
          <General.EventCardLabel>
            <General.EventCardLabelText>
              {AgeGroups[ageGroup]}
            </General.EventCardLabelText>
          </General.EventCardLabel>
        )}
        <ImageContainerHorizontal src={`${imageUrl}?w=150`} />

        <Details>
          <NameHorizontal>{name}</NameHorizontal>
          <DetailsHorizontal>
            {showOccurrenceDateRange && recurring ? (
              <Date>
                {moment().isSameOrAfter(occurrenceDateRangeStartDate)
                  ? 'Now until'
                  : moment(occurrenceDateRangeStartDate).format('MMM D')}{' '}
                - {moment(occurrenceDateRangeEndDate).format('MMM D')}{' '}
              </Date>
            ) : (
              <Date>{moment(startDate).format('ddd, MMM D | h:mm')}</Date>
            )}
            {venue && <VenueName>{venue.name}</VenueName>}
          </DetailsHorizontal>
        </Details>
      </ContainerHorizontal>
    )
  }
}
