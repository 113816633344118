//@flow
import * as React from 'react'
import { css } from '@emotion/core'

import icon from './tooltip.svg'
import Theme from '../../../utils/theme'

type Props = {
  title: ?React.Node,
  text: ?React.Node,
}

export default (props: Props) => {
  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;

        cursor: pointer;
        margin: 0 3px;
        transition: background-color 0.3s ease-out;
        &:hover {
          > div {
            visibility: visible;
          }
        }
      `}
    >
      <img
        src={icon}
        css={css`
          width: 100%;
          height: 100%;
          margin-bottom: 0;
          line-height: 1;
          transform: translateY(4px);
        `}
      />{' '}
      <div
        css={css`
          visibility: hidden;
          width: 400px;
          background-color: black;
          color: #fff;
          /* text-align: center; */
          padding: 24px;
          border-radius: 6px;
          position: absolute;
          z-index: 1;
          font-weight: normal;
          background-color: ${Theme.colors.background};
        `}
      >
        {props.text}
      </div>
    </div>
  )
}
