//@flow
import * as React from 'react'
import { push } from 'gatsby'
import { Query } from 'react-apollo'
import styled from '@emotion/styled'

import { EventCardRow } from './ui/EventCardRow'
import Loading from '../components/ui/Loading'
import Fonts from '../components/layoutHelpers/Fonts'

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`
const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`

type Props = {
  title?: string,
  query: string,
  formatEvents?: (events: Object[]) => Object[],
  showOccurrenceDateRange?: boolean,
}

class EventCardRowWithQuery extends React.PureComponent<Props> {
  render() {
    const { query, title, formatEvents, showOccurrenceDateRange } = this.props
    return (
      <Query query={query} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data }) => {
          if (error) {
            console.error(error)
          }
          return (
            <Container>
              {title && <Fonts.Title>{title}</Fonts.Title>}
              {!data || (!data.events && loading) ? (
                <LoadingContainer>
                  <Loading />
                </LoadingContainer>
              ) : (
                <EventCardRow
                  showOccurrenceDateRange={showOccurrenceDateRange}
                  events={
                    formatEvents ? formatEvents(data.events) : data.events
                  }
                  onEventClick={event => push(`/events/${event.slug}`)}
                />
              )}
            </Container>
          )
        }}
      </Query>
    )
  }
}

export default EventCardRowWithQuery
