//@flow
import * as React from 'react'
import { compose, withProps } from 'recompose'
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps'

const APIKEY = process.env.GATSBY_GOOGLE_MAP_API_KEY || ''

type Props = {
  coordinates: {
    lat: string,
    lng: string,
  },
  isMarkerShown?: boolean,
  zoomLevel?: number,
}

const Map = (props: Props) => {
  const { coordinates, isMarkerShown, zoomLevel } = props
  if (!APIKEY) {
    console.error('Missing google map api key')
    return null
  }
  return (
    <GoogleMap defaultZoom={zoomLevel} defaultCenter={coordinates}>
      {isMarkerShown && <Marker position={coordinates} />}
    </GoogleMap>
  )
}

Map.defaultProps = {
  zoomLevel: 15,
}

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${APIKEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(Map)
