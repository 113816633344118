//@flow
import * as React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { Flex, Box } from 'grid-styled/emotion'
import { gql } from 'apollo-boost'
import idx from 'idx'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { EventCard } from '../components/ui/EventCard'
import Tooltip from '../components/ui/Tooltip'
import EventCardRowHorizontalWithQuery from '../components/EventCardRowHorizontalWithQuery'
import TrendingEvents from '../components/TrendingEvents'
import Viewport from '../components/layoutHelpers/Viewport'
import Button from '../components/ui/Button'
import Page from '../components/ui/Page'
import Map from '../components/ui/Map'
import ageGroups from '../constants/ageGroups'
import { rhythm } from '../utils/typography'
import theme from '../utils/theme'
import JsPCI from '../bsb/JsPCI.bs'
import MetaSection from './MetaSection'

/**
 * In order to pass an `event` to bsb. We need to transform
 * the structure from Contentful -> gatsby-source-contentful -> PCI.Event.t
 */
const transformEventToBsb = e => {
  const newEvewnt = {
    ...e,
    venueId: idx(e, e => e.venue.id) || '',
    venue: {
      ...e.venue,
      //$FlowFixMe - description does not exist
      description: idx(e, e => e.venue.description.description) || '',
    },
  }

  return newEvewnt
}
const { Wrapper } = Page

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  ${theme.mq.lg} {
    margin: 60px 135px;
  }
`

const SecondContainer = styled.div`
  background-color: ${theme.colors.tertiaryFill};
`

const Img = styled.img`
  width: 100%;
`
const EventDetail = styled.div`
  width: 100%;
`

const EventDetailHeader = styled.div`
  margin-bottom: ${rhythm(1)};
`
const EventDetailBody = styled.div``
const EventDescription = styled.div``
const TicketDescription = styled.div``
const VenueDescription = styled.div``

const Title = styled.h1`
  color: ${theme.colors.primaryFont};
`

const SectionHeader = styled.h4`
  margin-bottom: 0.5rem;
  width: 100%;
`

const SectionContainer = styled.div`
  white-space: pre-wrap;
  margin-bottom: ${rhythm(1)};
`

const VenueHeader = styled.h3`
  color: ${theme.colors.primaryFont};
`

const VenueLink = styled.a`
  color: ${theme.colors.selection};
  cursor: pointer;
`

const VenueEventsContainer = ({ children, ...rest }) => (
  <Flex style={{ width: '100%' }} flexDirection={['column']} {...rest}>
    {children}
  </Flex>
)

const DescriptionContainer = styled.p`
  white-space: pre-wrap;
`

const queryRelatedEvents = gql`
  query($venueId: ID!) {
    venue(id: $venueId) {
      id
      name
      events: eventsHappeningSoon(limit: 5) {
        ...eventCardFields
      }
    }
  }
  ${EventCard.fragments.eventCardFields}
`

const EventTemplate = (props: *) => {
  const {
    data: { contentfulEvent: event },
  } = props

  if (!event) return null

  const {
    name,
    description,
    ticketDescription,
    links,
    mainImage,
    ageGroup,
    venue,
  } = event

  const VenueEvents = () => (
    <VenueEventsContainer>
      <Box mt={['40px']}>
        {venue && <VenueHeader>Other Events at {venue.name}</VenueHeader>}
      </Box>
      <Box>
        {venue && venue.contentful_id && (
          <EventCardRowHorizontalWithQuery
            query={queryRelatedEvents}
            variables={{ venueId: venue.contentful_id }}
            eventsSelector={data => idx(data, d => d.venue.events)}
          />
        )}
      </Box>
    </VenueEventsContainer>
  )

  return (
    <Layout>
      <MetaSection event={event} />
      <Page>
        <Wrapper>
          <Container>
            <EventDetail>
              <Flex flexDirection={['column', 'column', 'column', 'row']}>
                <Viewport.Mobile>
                  <Box width={[1]}>
                    <EventDetailHeader>
                      <Title>{name}</Title>
                      <SectionHeader>
                        When
                        {JsPCI.isEventRecurringJson(
                          transformEventToBsb(event)
                        ) && (
                          <Viewport.Desktop>
                            <Tooltip
                              title=""
                              text={
                                'We are actively working to improve the accuracy of event dates and time. Visit the official event website for complete details.'
                              }
                            />
                          </Viewport.Desktop>
                        )}
                      </SectionHeader>
                      <DateRange event={event} />
                      <SectionHeader>Where</SectionHeader>
                      {venue ? (
                        <React.Fragment>
                          <div>
                            {venue.name}
                            <br />
                            {venue.address && (
                              <React.Fragment>
                                {venue.address}
                                <br />
                                {venue.addressTwo && (
                                  <React.Fragment>
                                    {venue.addressTwo}
                                    <br />
                                  </React.Fragment>
                                )}
                                {venue.city}, {venue.state} {venue.zipCode}
                                <br />
                              </React.Fragment>
                            )}
                            {venue.phoneNumber}
                            {venue.links &&
                              Array.isArray(venue.links) &&
                              venue.links.map(l => (
                                <div key={l.id}>
                                  <VenueLink target="blank" href={l.url}>
                                    Venue website
                                  </VenueLink>
                                </div>
                              ))}
                          </div>
                        </React.Fragment>
                      ) : (
                        <p> No location specified </p>
                      )}
                    </EventDetailHeader>
                  </Box>
                </Viewport.Mobile>
                <Box
                  width={[1, 1, 1, 1 / 2, 1 / 2, 1 / 2]}
                  mr={['0px', '0px', '0px', '20px']}
                >
                  {mainImage && <Img srcSet={mainImage.resolutions.srcSet} />}
                  <Viewport.Desktop>
                    <VenueEvents />
                  </Viewport.Desktop>
                </Box>
                <Box
                  width={[1, 1, 1, 1 / 2, 1 / 2, 1 / 2]}
                  ml={['0px', '0px', '0px', '20px']}
                >
                  <Viewport.Desktop>
                    <EventDetailHeader>
                      <Title>{name}</Title>

                      <SectionHeader>
                        When
                        {JsPCI.isEventRecurringJson(
                          transformEventToBsb(event)
                        ) && (
                          <Viewport.Desktop>
                            <Tooltip
                              title=""
                              text={
                                'We are actively working to improve the accuracy of event dates and time. Visit the official event website for complete details.'
                              }
                            />
                          </Viewport.Desktop>
                        )}
                      </SectionHeader>
                      <DateRange event={event} />
                      <SectionHeader>Where</SectionHeader>
                      {venue ? (
                        <React.Fragment>
                          <div>
                            {venue.name}
                            <br />
                            {venue.address && (
                              <React.Fragment>
                                {venue.address}{' '}
                                {venue.addressTwo && (
                                  <React.Fragment>
                                    {venue.addressTwo}
                                    <br />
                                  </React.Fragment>
                                )}
                                {venue.city}, {venue.state} {venue.zipCode}
                                <br />
                              </React.Fragment>
                            )}
                            {venue.phoneNumber}
                            {venue.links &&
                              Array.isArray(venue.links) &&
                              venue.links.map(l => (
                                <div key={l.id}>
                                  <VenueLink target="blank" href={l.url}>
                                    Venue website
                                  </VenueLink>
                                </div>
                              ))}
                          </div>
                        </React.Fragment>
                      ) : (
                        <p> No location specified </p>
                      )}
                    </EventDetailHeader>
                  </Viewport.Desktop>
                  <EventDetailBody>
                    <EventDescription>
                      <SectionHeader>About the Event</SectionHeader>
                      {ageGroup && <div>Ages: {ageGroups[ageGroup]}</div>}
                      {links &&
                        Array.isArray(links) &&
                        links
                          .filter(l => l.type === 'EventWebsite')
                          .map(l => (
                            <div key={l.id}>
                              <VenueLink target="blank" href={l.url}>
                                Event website
                              </VenueLink>
                            </div>
                          ))}
                      {description && (
                        <DescriptionContainer
                          dangerouslySetInnerHTML={{
                            __html: description.description,
                          }}
                        />
                      )}
                    </EventDescription>
                    <TicketDescription>
                      <SectionHeader>Ticket Information</SectionHeader>
                      <SectionContainer>
                        {ticketDescription && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: ticketDescription.ticketDescription,
                            }}
                          />
                        )}
                        {links &&
                          Array.isArray(links) &&
                          links
                            .filter(l => l.type === 'TicketWebsite')
                            .map(l => (
                              <React.Fragment key={l.id}>
                                <Button onClick={() => window.open(l.url)}>
                                  Buy Tickets
                                </Button>
                              </React.Fragment>
                            ))}
                      </SectionContainer>
                    </TicketDescription>
                    {/* <DatesAndTimes event={event} /> */}
                    {venue && (
                      <React.Fragment>
                        <VenueDescription>
                          <SectionHeader>About the Venue</SectionHeader>
                          {venue && venue.description && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: venue.description.description,
                              }}
                            />
                          )}
                        </VenueDescription>
                        {venue.locationCoordinates && (
                          <Map
                            isMarkerShown={true}
                            coordinates={{
                              ...venue.locationCoordinates,

                              lng: idx(venue, v => v.locationCoordinates.lon),
                            }}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </EventDetailBody>

                  <Viewport.Mobile>
                    <VenueEvents />
                  </Viewport.Mobile>
                </Box>
              </Flex>

              {/* <Map isMarkerShown /> */}
            </EventDetail>
          </Container>
        </Wrapper>
        <SecondContainer>
          <TrendingEvents />
        </SecondContainer>
      </Page>
    </Layout>
  )
}

const DateRange = ({ event }: { event: PCIEvent }) => {
  const DATE_FORMAT = 'dddd, MMMM Do'

  const { startDate, endDate: occEndDate } = JsPCI.getEventDateRangeJson(
    transformEventToBsb(event)
  )

  if (!JsPCI.isEventRecurringJson(transformEventToBsb(event))) {
    return <p>{moment(startDate).format(DATE_FORMAT + ' LT')}</p>
  }

  return (
    <p>
      {moment(startDate).format(DATE_FORMAT)} -{' '}
      {moment(occEndDate).format(DATE_FORMAT)}
      <br />
      {`${moment(startDate).format('LT')} to ${moment(event.endDate).format(
        'LT'
      )}`}
    </p>
  )
}

export const query = graphql`
  query GetEvent($eventId: String!) {
    contentfulEvent(id: { eq: $eventId }) {
      id
      name
      shortDescription
      description {
        description
      }
      ticketDescription {
        ticketDescription
      }
      slug
      ageGroup
      startDate
      endDate
      type
      mainImage {
        id
        description
        resolutions(width: 350) {
          width
          srcSet
        }
        fixed(width: 800) {
          src
        }
      }
      occurrences {
        id
        endDate
        type
      }
      venue {
        id
        contentful_id
        name
        shortDescription
        description {
          description
        }
        phoneNumber
        address
        zipCode
        city
        state
        country
        locationCoordinates {
          lat
          lon
        }
        address
        address2
        zipCode
        city
        state
        country
        links {
          id
          name
          url
        }
      }
      links {
        id
        name
        url
        type
      }
    }
  }
`

export default EventTemplate
