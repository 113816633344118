//@flow
import React from 'react'
import styled from '@emotion/styled'
import { EventCard, EventCardHorizontal } from './EventCard'

import theme from '../../utils/theme'
const Container = styled.div`
  width: 100%;
  position: relative;
`

const GUTTER = 10

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  transition: all 1s ease-out;
`

const Col = styled.div`
  display: flex;
  flex: 1;
  margin-right: ${GUTTER}px;
  min-width: calc(${(1 / 6) * 100}% - ${GUTTER}px);
  &:nth-of-type(6) {
    display: none;
  }
  ${theme.mq.xxl} {
    min-width: calc(${(1 / 7) * 100}% - ${GUTTER}px);
    &:nth-of-type(7) {
      display: flex;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  & > div {
    margin-left: 3px;
  }
`

const RowVertical = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

RowVertical.Item = styled.div`
  height: 95px;
  margin-bottom: 11px;

  ${theme.mq.md} {
    height: 102px;
  }
`

type Props = {
  events: PCIEvent[],
  showOccurrenceDateRange?: boolean,
  // onEventClick: (event: PCIEvent) => any,
}

type State = {
  scrollFactor: number,
}

export class EventCardRow extends React.PureComponent<Props, State> {
  render() {
    const { events = [], showOccurrenceDateRange } = this.props

    return (
      <Container>
        <Row>
          {events.map((event, i) => {
            return (
              <Col key={`${event.id}-${i}`}>
                <EventCard
                  event={event}
                  to={`/events/${event.slug}`}
                  showOccurrenceDateRange={showOccurrenceDateRange}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    )
  }
}

export class EventCardRowVertical extends React.PureComponent<Props> {
  render() {
    const { events = [], showOccurrenceDateRange } = this.props

    return (
      <RowVertical>
        {Array.isArray(events) &&
          events.map(event => {
            return (
              <RowVertical.Item key={event.id}>
                <EventCardHorizontal
                  event={event}
                  to={`/events/${event.slug}`}
                  showOccurrenceDateRange={showOccurrenceDateRange}
                />
              </RowVertical.Item>
            )
          })}
      </RowVertical>
    )
  }
}
