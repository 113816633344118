//@flow
import * as React from 'react'
import { push } from 'gatsby'
import { Query } from 'react-apollo'
import styled from '@emotion/styled'

import { EventCardRowVertical } from './ui/EventCardRow'
import theme from '../utils/theme'

const Container = styled.div`
  width: 100%;
`

const Title = styled.h2`
  color: ${theme.colors.primaryFont};
`

type Props = {
  title?: string,
  query: string,
  variables?: Object,
  formatEvents?: (events: Object[]) => Object[],
  eventsSelector?: (data: Object) => any,
  showOccurrenceDateRange?: boolean,
}

class EventCardRowHoriztonalWithQuery extends React.PureComponent<Props> {
  render() {
    const {
      query,
      title,
      formatEvents,
      variables = {},
      eventsSelector,
      showOccurrenceDateRange,
    } = this.props
    return (
      <Query
        query={query}
        fetchPolicy={'cache-and-network'}
        variables={variables}
      >
        {({ loading, error, data }) => {
          if (error) {
            return null
          }

          const events = eventsSelector
            ? eventsSelector(data)
            : data && data.events
            ? data.events
            : null

          if (!events && loading) {
            return 'Loading'
          }

          if (!events) {
            return null
          }

          return (
            <Container>
              {title && <Title>{title}</Title>}
              <EventCardRowVertical
                events={formatEvents ? formatEvents(events) : events}
                onEventClick={event => push(`/events/${event.slug}`)}
                showOccurrenceDateRange={showOccurrenceDateRange}
              />
            </Container>
          )
        }}
      </Query>
    )
  }
}

export default EventCardRowHoriztonalWithQuery
