//@flow
import * as React from 'react'
import { BarLoader } from 'react-spinners'

type State = {|
  loading: boolean,
|}
class Loading extends React.Component<*, State> {
  state = {
    loading: true,
  }
  render() {
    return (
      <div className="sweet-loading">
        <BarLoader
          loaderStyle={{
            margin: '0 auto',
            borderColor: '#DB245D',
            color: '#DB245D',
          }}
          sizeUnit={'px'}
          size={'20'}
          color={'#DB245D'}
          width={200}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

export default Loading
