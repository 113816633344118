// @flow
import * as React from 'react'
import Helmet from 'react-helmet'
import idx from 'idx'
type Props = {
  event: PCIEvent,
}

export default ({ event }: Props) => {
  const { name, shortDescription, mainImage } = event

  const mainImageUrl = idx(mainImage, m => m.fixed.src) || ''

  return (
    <Helmet>
      <meta property="og:title" content={name} />
      <meta property="og:description" content={shortDescription} />
      {mainImageUrl && (
        <meta property="og:image" content={`https:${mainImageUrl}`} />
      )}
      <meta
        property="og:url"
        content={`${process.env.GATSBY_SITE_BASE_URL || ''}/events/${
          event.slug
        }`}
      />
      <script type="application/ld+json">
        {`{
  "@context": "http://schema.org",
  "@type": "Event",
  "name": "${event.name || ''}",
  "startDate": "${event.startDate || ''}",
  "location": {
    "@type": "Place",
    "name": "${idx(event, e => e.venue.name) || ''}",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "${idx(event, e => e.venue.address) || ''}",
      "addressLocality": "${idx(event, e => e.venue.city) || ''}",
      "postalCode": "${idx(event, e => e.venue.zipCode) || ''}",
      "addressRegion": "${idx(event, e => e.venue.state) || ''}",
      "addressCountry": "US"
    }
  },
  "image": [
    "${`https:${mainImageUrl}`}"
   ],
  "description": "${event.shortDescription || ''}",
  "endDate": "${event.endDate || ''}"

}`}
      </script>
    </Helmet>
  )
}
