//@flow
import styled from '@emotion/styled'
import theme from '../../utils/theme'

//Responsive
export const Mobile = styled.div`
  display: inline;
  ${theme.mq.lg} {
    display: none;
  }
`

export const Desktop = styled.div`
  display: none;
  ${theme.mq.lg} {
    display: inline;
  }
`

const Viewport = {
  Mobile,
  Desktop,
}

export default Viewport
