// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var PCI$PciDataSync = require("./PCI.bs.js");

function generateOccurrencesByEventJson(eventJson) {
  var $$event = PCI$PciDataSync.Decode[/* event */4](eventJson);
  var list = $$Array.of_list(PCI$PciDataSync.generateOccurrencesByEvent($$event));
  return $$Array.map((function (e) {
                return PCI$PciDataSync.Encode[/* event */0](e);
              }), list);
}

function getEventDateRangeJson(eventJson) {
  var $$event = PCI$PciDataSync.Decode[/* event */4](eventJson);
  var match = PCI$PciDataSync.getEventDateRange($$event);
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "startDate",
                match[0].toJSON()
              ],
              /* :: */[
                /* tuple */[
                  "endDate",
                  match[1].toJSON()
                ],
                /* [] */0
              ]
            ]);
}

function isEventRecurringJson(e) {
  return PCI$PciDataSync.isEventRecurring(PCI$PciDataSync.Decode[/* event */4](e));
}

exports.generateOccurrencesByEventJson = generateOccurrencesByEventJson;
exports.getEventDateRangeJson = getEventDateRangeJson;
exports.isEventRecurringJson = isEventRecurringJson;
/* PCI-PciDataSync Not a pure module */
