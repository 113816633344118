// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Moment = require("moment");

var Duration = /* module */[];

function add(duration, moment) {
  var clone = moment.clone();
  clone.add(duration);
  return clone;
}

function subtract(duration, moment) {
  var clone = moment.clone();
  clone.subtract(duration);
  return clone;
}

function startOf(timeUnit, moment) {
  var clone = moment.clone();
  clone.startOf((function () {
            switch (timeUnit) {
              case -800601667 : 
                  return "year";
              case -934569940 : 
                  return "quarter";
              case 207603520 : 
                  return "month";
              case -822779916 : 
                  return "week";
              case 4994652 : 
                  return "day";
              case -988622556 : 
                  return "hour";
              case -754457900 : 
                  return "minute";
              case -465055884 : 
                  return "second";
              case -822817983 : 
                  return "millisecond";
              
            }
          })());
  return clone;
}

function endOf(timeUnit, moment) {
  var clone = moment.clone();
  clone.endOf((function () {
            switch (timeUnit) {
              case -800601667 : 
                  return "year";
              case -934569940 : 
                  return "quarter";
              case 207603520 : 
                  return "month";
              case -822779916 : 
                  return "week";
              case 4994652 : 
                  return "day";
              case -988622556 : 
                  return "hour";
              case -754457900 : 
                  return "minute";
              case -465055884 : 
                  return "second";
              case -822817983 : 
                  return "millisecond";
              
            }
          })());
  return clone;
}

function setMillisecond(millisecond, moment) {
  var clone = moment.clone();
  clone.millisecond(millisecond);
  return clone;
}

function setSecond(second, moment) {
  var clone = moment.clone();
  clone.second(second);
  return clone;
}

function setMinute(minute, moment) {
  var clone = moment.clone();
  clone.minute(minute);
  return clone;
}

function setHour(hour, moment) {
  var clone = moment.clone();
  clone.hour(hour);
  return clone;
}

function setDate(date, moment) {
  var clone = moment.clone();
  clone.date(date);
  return clone;
}

function setDay(day, moment) {
  var clone = moment.clone();
  clone.day(day);
  return clone;
}

function setWeekday(weekday, moment) {
  var clone = moment.clone();
  clone.weekday(weekday);
  return clone;
}

function setIsoWeekday(isoWeekday, moment) {
  var clone = moment.clone();
  clone.isoWeekday(isoWeekday);
  return clone;
}

function setDayOfYear(dayOfYear, moment) {
  var clone = moment.clone();
  clone.dayOfYear(dayOfYear);
  return clone;
}

function setWeek(week, moment) {
  var clone = moment.clone();
  clone.week(week);
  return clone;
}

function setIsoWeek(isoWeek, moment) {
  var clone = moment.clone();
  clone.isoWeek(isoWeek);
  return clone;
}

function setQuarter(quarter, moment) {
  var clone = moment.clone();
  clone.quarter(quarter);
  return clone;
}

function setWeekYear(weekYear, moment) {
  var clone = moment.clone();
  clone.weekYear(weekYear);
  return clone;
}

function setIsoWeekYear(isoWeekYear, moment) {
  var clone = moment.clone();
  clone.weekYear(isoWeekYear);
  return clone;
}

function setMonth(months, moment) {
  var clone = moment.clone();
  clone.month(months);
  return clone;
}

function setYear(years, moment) {
  var clone = moment.clone();
  clone.year(years);
  return clone;
}

var Moment$1 = /* module */[
  /* add */add,
  /* subtract */subtract,
  /* startOf */startOf,
  /* endOf */endOf,
  /* setMillisecond */setMillisecond,
  /* setSecond */setSecond,
  /* setMinute */setMinute,
  /* setHour */setHour,
  /* setDate */setDate,
  /* setDay */setDay,
  /* setWeekday */setWeekday,
  /* setIsoWeekday */setIsoWeekday,
  /* setDayOfYear */setDayOfYear,
  /* setWeek */setWeek,
  /* setIsoWeek */setIsoWeek,
  /* setQuarter */setQuarter,
  /* setWeekYear */setWeekYear,
  /* setIsoWeekYear */setIsoWeekYear,
  /* setMonth */setMonth,
  /* setYear */setYear
];

function momentWithUnix(timestamp) {
  return Moment(timestamp * 1000.0);
}

function moment(format, value) {
  if (format !== undefined) {
    return Moment(value, format);
  } else {
    return Moment(value);
  }
}

exports.Duration = Duration;
exports.Moment = Moment$1;
exports.momentWithUnix = momentWithUnix;
exports.moment = moment;
/* moment Not a pure module */
