//@flow
import React from 'react'

import { gql } from 'apollo-boost'

import { EventCard } from '../components/ui/EventCard'
import EventCardList from '../components/EventCardList'
import EventCardListContainer from '../components/ui/EventCardListContainer'
import Viewport from './layoutHelpers/Viewport'

const queryTrending = gql`
  query {
    events: allTrendingEvents(limit: 7) {
      ...eventCardFields
    }
  }
  ${EventCard.fragments.eventCardFields}
`

export default function TrendingEvents() {
  return (
    <EventCardListContainer title={'Popular Events'}>
      <Viewport.Desktop>
        <EventCardList query={queryTrending} showOccurrenceDateRange={true} />
      </Viewport.Desktop>
      <Viewport.Mobile>
        <EventCardList
          query={queryTrending}
          eventCardHorizontal={true}
          showOccurrenceDateRange={true}
        />
      </Viewport.Mobile>
    </EventCardListContainer>
  )
}
