//@flow

import { css } from '@emotion/core'
import theme from './theme'
export const hoverShadow = css`
  ${theme.mq.lg} {
    &:hover::after {
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      opacity: 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
      transition: opacity 0.3s ease-in-out;
    }
  }
`
